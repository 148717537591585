<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">지식컨텐츠현황</h1>
    <!-- 상단 조회폼 시작 -->
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="$store.getters['userStore/GE_USER_COMPANY']"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="gridSearch.ASP_NEWCUST_KEY"
              @change="changeAspCustKey"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="SRCH_DAY_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="검색일자"
              placeholder="선택하세요"
              v-model="gridSearch.SRCH_DAY"
            >
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="gridSearch.DAY_FROM"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="startDate"
                ></v-date-picker>
              <v-date-picker
                  v-model="gridSearch.DAY_TO"
                  dark
                  color="#F7AFAE"
                  no-title
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="endDate"
                ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
          <div class="ml-2 d-inline">
            <v-btn-toggle
              v-model="datePeriod"
            >
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAll')" outlined class="btn-default" @click="changeBtnDate('ALL')">전체</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btn3Day')" outlined class="btn-default" @click="changeBtnDate('TO_DAY_3')">3일</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btn7Day')" outlined class="btn-default" @click="changeBtnDate('TO_DAY_7')">7일</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btn1Month')" outlined class="btn-default" @click="changeBtnDate('TO_MONTH_1')">1개월</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btn3Month')" outlined class="btn-default" @click="changeBtnDate('TO_MONTH_3')">3개월</v-btn>
            </v-btn-toggle>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-inp sm">
            <v-select
              :items="SRCH_ATTR_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="소속"
              no-data-text="전체"
              placeholder="선택하세요"
              v-model="gridSearch.SRCH_ATTR">
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_A_ITEMS"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              no-data-text="전체"
              label="소속A"
              placeholder="선택하세요"
              v-model="gridSearch.USER_ATTR_A"
              >
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_B_ITEMS"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              no-data-text="전체"
              label="소속B"
              placeholder="선택하세요"
              v-model="gridSearch.USER_ATTR_B"
              >
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_C_ITEMS"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              no-data-text="전체"
              label="소속C"
              placeholder="선택하세요"
              v-model="gridSearch.USER_ATTR_C"
              >
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_D_ITEMS"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              no-data-text="전체"
              label="소속D"
              placeholder="선택하세요"
              v-model="gridSearch.USER_ATTR_D"
              >
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="SRCH_USER_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="사용자"
              placeholder="선택하세요"
              v-model="gridSearch.SRCH_USER">
            </v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="등록자 ID" id="id" outlined
            hide-details
            v-model="gridSearch.USER_ID"
            >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="등록자 이름" id="id" outlined
            hide-details
            v-model="gridSearch.USER_NM"
            >
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="등록자 닉네임" id="id" outlined
            hide-details
            v-model="gridSearch.USER_NICK"
            >
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')" dark small class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain @click="showDialog('MP02')">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <v-dialog max-width="1400" max-height="1200" persistent v-model="dialogMP02" v-if="dialogMP02" content-class="square-modal">
             <dialog-MP02
              headerTitle="고객정보"
              :userInfo="userInfo"
              @selectUser="[hideDialog('MP02'), setUser()]"
              @hide="hideDialog('MP02')"
              >
             </dialog-MP02>
          </v-dialog>
          <!-- 새로고침 버튼 -->
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')" dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain @click="resetBtn()">
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>
        <div class="mt-2">
          <div class="form-inp sm">
            <v-select
              :items="DIV_ID_1LEVEL_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="컨텐츠분류"
              placeholder="선택하세요"
              v-model="gridSearch.DIV_ID_1LEVEL"
              @change="changeDivId('1')"
              >
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="DIV_ID_2LEVEL_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label=""
              placeholder="선택하세요"
              v-model="gridSearch.DIV_ID_2LEVEL"
              @change="changeDivId('2')"
              >
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="DIV_ID_3LEVEL_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label=""
              placeholder="선택하세요"
              v-model="gridSearch.DIV_ID_3LEVEL"
              >
            </v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="컨텐츠번호" id="id" outlined
            hide-details
            v-model="gridSearch.CNTNT_NO"
            max="50"
            >
          </v-text-field>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'TWB110','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="컨텐츠상태"
              placeholder="선택하세요"
              v-model="gridSearch.SAVE_STAT_CD">
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="RCMD_SEL_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="추천여부"
              placeholder="선택하세요"
              v-model="gridSearch.RCMD_SEL">
            </v-select>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-inp sm">
            <v-select
              :items="SRCH_DIV_ITEMS"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="제목/내용"
              placeholder="선택하세요"
              v-model="gridSearch.SRCH_DIV">
            </v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            style="width: 352px"
            name="name" label="검색어" id="id" outlined
            hide-details
            v-model="gridSearch.SRCH_TEXT"
            max="100"
            >
          </v-text-field>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'TWB109','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="색인상태"
              placeholder="선택하세요"
              v-model="gridSearch.IDX_STAT_CD">
            </v-select>
          </div>
          <v-radio-group v-model="gridSearch.rdoOrder" row class="radio-inline ml-2">
            <template v-slot:label >
              <span class="in-label">정렬조건</span>
            </template>
            <v-radio v-for="(rad01,index) in radioGroup" :key="radioGroup[index].text" :label="rad01.text"
              :on-icon="'svg-rad-on'"
              :off-icon="'svg-rad-off'"
              :value="rad01.value">
            </v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="search()">조회</v-btn>
      </div>
    </div>
    <!-- 상단 조회폼 끝   -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        컨텐츠 현황
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default" @click="common_confirm(KKKN_MSG.CONFIRM_NEW, newRtn,'NEW',null,'','chk')">신규</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')" outlined class="btn-default ml-2" @click="showDetail()">상세</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMove')" outlined class="btn-default ml-2" @click="moveRtn()">이동</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" @click="common_confirm('삭제하시겠습니까?', deleteRtn, '', null, '', 'error')">삭제</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody')" outlined class="btn-default ml-2" @click="newRtn('MODIFY')">수정</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRestore')" outlined class="btn-point ml-4" @click="processRtn('DEL_CANCEL')">복원</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAccess')" outlined class="btn-point ml-2" @click="processRtn('APPROVAL')">승인</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelIndex')" outlined class="btn-etc ml-4" @click="processRtn('INDEX_DEL')">색인삭제</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnReIndex')" outlined class="btn-point ml-2" @click="processRtn('INDEX_READY')">재색인</v-btn>
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')"
            outlined
            class="btn-point ml-2"
            @click="downloadExcel('divGridKmList', '지식컨텐츠현황')"
          >
            엑셀다운로드
          </v-btn>
          <!-- 이동 모달 M510201P01-->
          <v-dialog max-width="655" persistent
            v-model="dialogM510201P01" v-if="dialogM510201P01" content-class="square-modal"
            >
            <dialog-M510201P01
              headerTitle="지식컨텐츠 분류선택"
              :gridChecked="gridChecked"
              :ASP_NEWCUST_KEY="gridSearch.ASP_NEWCUST_KEY"
              @hide="hideDialog('M510201P01')"
              @submit="callBack('M510201P01')"
            >
            </dialog-M510201P01>
          </v-dialog>

          <!-- 신규 모달 M510201P02-->
          <v-dialog max-width="1400" persistent
            v-model="dialogM510201P02" v-if="dialogM510201P02" content-class="square-modal"
            >
            <dialog-M510201P02
              headerTitle="지식컨텐츠 분류선택"
              :ASP_NEWCUST_KEY="gridSearch.ASP_NEWCUST_KEY"
              :params="registParams"
              @hide="hideDialog('M510201P02')"
              @submit="callBack('M510201P02')"
            >
            </dialog-M510201P02>
          </v-dialog>

          <!-- 상세 모달 M510201P03-->
          <v-dialog max-width="1400" persistent
            v-model="dialogM510201P03" v-if="dialogM510201P03" content-class="square-modal"
            >
            <dialog-M510201P03
              headerTitle="지식컨텐츠 상세"
              :CNTNT_ID="gridSelectedData.CNTNT_ID"
              :ASP_NEWCUST_KEY="gridSearch.ASP_NEWCUST_KEY"
              @hide="hideDialog('M510201P03')"
              @recommendDialog="showDialog('M510201P04')"
              @modifyDialog="showDialog('M510201P05')"
            >
            </dialog-M510201P03>
          </v-dialog>

          <!-- 상세 추천목록 모달 M510201P04-->
          <v-dialog max-width="655" persistent
            v-model="dialogM510201P04" v-if="dialogM510201P04" content-class="square-modal"
            >
            <dialog-M510201P04
              headerTitle="추천목록"
              @hide="hideDialog('M510201P04')"
              :CNTNT_ID="gridSelectedData.CNTNT_ID"
              :ASP_NEWCUST_KEY="gridSearch.ASP_NEWCUST_KEY"
            >
            </dialog-M510201P04>
          </v-dialog>

          <!-- 상세 수정이력 모달 M510201P05-->
          <v-dialog max-width="655" persistent
            v-model="dialogM510201P05" v-if="dialogM510201P05" content-class="square-modal"
            >
            <dialog-M510201P05
              headerTitle="수정이력"
              @hide="hideDialog('M510201P05')"
              :CNTNT_ID="gridSelectedData.CNTNT_ID"
              :ASP_NEWCUST_KEY="gridSearch.ASP_NEWCUST_KEY"
            >
            </dialog-M510201P05>
          </v-dialog>
          <v-dialog max-width="655" persistent
            v-model="dialogFileList" v-if="dialogFileList" content-class="square-modal"
            >
            <dialog-FileList
              headerTitle="첨부파일 목록"
              @hide="hideDialog('FileList')"
              :FILE_GROUP_KEY="FILE_GROUP_KEY"
              :ASP_NEWCUST_KEY="gridSearch.ASP_NEWCUST_KEY"
              busiType="km"
              pathType="files"
            >
            </dialog-FileList>
          </v-dialog>



        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="CNTNT_NO"
          id="divGridKmList"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          fixed-header
          calculate-widths
          @page-count="pageCount = $event"
          height="405px"
          no-data-text="데이터가 존재하지 않습니다."
          show-select
          v-model="gridChecked"
          @click:row="setGridSelect"
          :item-class="isActiveRow"
        >
        <template v-slot:item.FILE_YN = {item}>
          <v-icon small v-if="item.FILE_YN == 'Y'" style="cursor:pointer" @click="showFileView(item.FILE_GROUP_KEY)">mdi-paperclip</v-icon>
        </template>
        <!-- FILE_YN -->
        <!-- mdi-paperclip -->

        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DialogM510201P01 from "./M510201P01.vue";
import DialogM510201P02 from "./M510201P02.vue";
import DialogM510201P03 from "./M510201P03.vue";
import DialogM510201P04 from "./M510201P04.vue";
import DialogM510201P05 from "./M510201P05.vue";
import DialogFileList from "@/components/FileList.vue"
import DialogMP02 from "./MP02.vue";
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M510201", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM510201P01,
    DialogM510201P02,
    DialogM510201P03,
    DialogM510201P04,
    DialogM510201P05,
    DialogFileList,
    DialogMP02,
  },
  mixins : [mixin],
  data() {
    return {
      dialogM510201P01: false,
      dialogM510201P02: false,
      dialogM510201P03: false,
      dialogM510201P04: false,
      dialogM510201P05: false,
      dialogFileList: false,
      dialogMP02: false,
      dropItems: [],
      groupCdList : ['TWB109','TWB110'],
      allCodeList : [],
      SRCH_DAY_ITEMS:[
        {text : "등록일자", value:"REG_DTTM"},
        {text : "수정일자", value:"UPD_DTTM"},
      ],
      SRCH_ATTR_ITEMS:[
        {text : "등록자 소속", value:"REGR_ATTR"},
        {text : "수정자 소속", value:"AMDR_ATTR"},
      ],
      SRCH_USER_ITEMS:[
        {text : "등록자", value:"REGR_ID"},
        {text : "수정자", value:"AMDR_ID"},
      ],
      RCMD_SEL_ITEMS:[
        {text : "전체", value:""},
        {text : "추천", value:"Y"},
        {text : "미추천", value:"N"},
      ],
      SRCH_DIV_ITEMS:[
        {text : "제목", value:"TITL"},
        {text : "내용", value:"CONTN"},
        {text : "제목 + 내용", value:"TITL||CONTN"},
      ],
      DIV_ID_1LEVEL_ITEMS:[],
      DIV_ID_2LEVEL_ITEMS:[],
      DIV_ID_3LEVEL_ITEMS:[],
      USER_ATTR_A_ITEMS : [],
      USER_ATTR_B_ITEMS : [],
      USER_ATTR_C_ITEMS : [],
      USER_ATTR_D_ITEMS : [],
      gridSearch : {
        SAVE_STAT_CD : "",
        SRCH_DAY : "REG_DTTM",
        SRCH_ATTR : "REGR_ATTR",
        SRCH_USER : "REGR_ID",
        RCMD_SEL : "",
        SRCH_DIV : "TITL",
        DIV_ID_1LEVEL : "",
        DIV_ID_2LEVEL : "",
        DIV_ID_3LEVEL : "",
        rdoOrder : "REG_DTTM",
        IDX_STAT_CD : "",
        USER_ATTR_A : "",
        USER_ATTR_B : "",
        USER_ATTR_C : "",
        USER_ATTR_D : "",
        DAY_FROM : "",
        DAY_TO : "",
        USER_ID : "",
        USER_NM : "",
        USER_NICK : "",
      },
      menu1: false,
      mstrDatePattern : {},
      datePeriod: 0,
      radioGroup: [{
          text: '등록일자',
          value : 'REG_DTTM'
        },
        {
          text: '수정일자',
          value : 'UPD_DTTM'
        },
      ],
      radioGroupSelected: '',
      page: 1,
      gridDataText: [],
      gridSelectedData:{},
      gridChecked : [],
      gridDataHeaders: [
        {text: '번호',          value:"CNTNT_NO"	 	  ,align:"center"	, sortable: true,},
        {text: '제목',          value:"TITL"			    ,align:"left"		 , sortable: true,},
        {text: '컨텐츠분류',    value:"FULL_DIV_NM"	  ,align:"left"		 , sortable: true,},
        {text: '수정일시',      value:"UPD_DTTM"		  ,align:"center"	, sortable: true,},
        {text: '수정자',        value:"AMDR_NM"			  ,align:"left"		 , sortable: true,},
        {text: '등록일시',      value:"REG_DTTM"		  ,align:"center"	, sortable: true,},
        {text: '등록자',        value:"REGR_NM"			  ,align:"left"		 , sortable: true,},
        {text: '첨부',          value:"FILE_YN"			  ,align:"center"	, sortable: true,},
        {text: '조회수',        value:"INQ_CNT"			  ,align:"center"	, sortable: true,},
        {text: '컨텐츠상태',    value:"SAVE_STAT_NM"  ,align:"center"	, sortable: true,},
        {text: '색인상태',      value:"IDX_STAT_NM"   ,align:"center"	, sortable: true,},
      ],
      pageCount: 0,
      itemsPerPage: 50,
      totalVisible: 10,
      userInfo: {},
      KKKN_MSG: {
        NO_EXIST_CNTNT_ID       	: "선택된 컨텐츠가 없습니다."
        ,DO_NOT_MOVE       			: "이동할 수 없는 데이터가 포함되어 있습니다."
        ,CONFIRM_NEW					: "동일, 유사 게시물 존재여부를 확인(검색) 바랍니다. <br/> 동일, 유사 게시물이 존재하면 등록이 아닌 수정을 해야 합니다.<br/>신규 등록을 하시겠습니까?"
      },
      registParams : {
        STATE : "",
        CNTNT_ID : "",
        CNTNT_KEY : "",
        DIV_ID_1LEVEL : "",
        DIV_ID_2LEVEL : "",
        DIV_ID_3LEVEL : "",
        SAVE_STAT_CD : "",
      },
      FILE_GROUP_KEY : ""
    };
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
    let DIV_ID_1LEVEL = await this.mixin_getDivCodeData('0');
    this.DIV_ID_1LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_1LEVEL,'0','전체');
    this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get("","","전체");
    this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get("","","전체");
    this.mstrDatePattern = await this.mixin_getSvrPrevDate("YYYY-MM-DD");

  },
  methods: {
    search(){
      this.gridSelectedData = {};
      this.gridChecked = [];
      this.page = 1;
      this.selectRtnKm();
    },

    async selectRtnKm(){
       if(this.gridSearch.ASP_NEWCUST_KEY == '' || this.gridSearch.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;

      }else{
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/km/content/manage/sttus/list";
        requestData.headers["SERVICE"] = "km.content.manage.sttus";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;
        requestData.headers["ROW_CNT"] = this.itemsPerPage;
        requestData.headers["PAGES_CNT"] = this.page;
        // sendData 세팅
        requestData.sendData = this.gridSearch;

        if(this.gridSearch.DIV_ID_1LEVEL != "" && this.gridSearch.DIV_ID_2LEVEL != "" && this.gridSearch.DIV_ID_3LEVEL != ""){
          requestData.sendData['SPST_DIV_ID'] = this.gridSearch.DIV_ID_3LEVEL;
        }else if(this.gridSearch.DIV_ID_1LEVEL != "" && this.gridSearch.DIV_ID_2LEVEL != "" && this.gridSearch.DIV_ID_3LEVEL == ""){
          requestData.sendData['SPST_DIV_ID'] = this.gridSearch.DIV_ID_2LEVEL;
        }else if(this.gridSearch.DIV_ID_1LEVEL != "" && this.gridSearch.DIV_ID_2LEVEL == "" && this.gridSearch.DIV_ID_3LEVEL == ""){
          requestData.sendData['SPST_DIV_ID'] = this.gridSearch.DIV_ID_1LEVEL;
        }

        requestData.sendData['ATTR'] = this.gridSearch.SRCH_ATTR;
        requestData.sendData['USER'] = this.gridSearch.USER_ID;
        requestData.sendData[this.gridSearch.DAY_FROM +"_FROM"] = this.gridSearch.DAY_FROM;
        requestData.sendData[this.gridSearch.DAY_FROM +"_TO"] = this.gridSearch.DAY_TO;

        requestData.sendData['SORT_ORDR'] = this.gridSearch.rdoOrder;
        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.gridDataText = response.DATA;
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
      }
    },
    async changeAspCustKey(event) {
      this.USER_ATTR_A_ITEMS = await this.mixin_attr_get(this.gridSearch.ASP_NEWCUST_KEY, "A", "전체");
      this.USER_ATTR_B_ITEMS = await this.mixin_attr_get(this.gridSearch.ASP_NEWCUST_KEY, "B", "전체");
      this.USER_ATTR_C_ITEMS = await this.mixin_attr_get(this.gridSearch.ASP_NEWCUST_KEY, "C", "전체");
      this.USER_ATTR_D_ITEMS = await this.mixin_attr_get(this.gridSearch.ASP_NEWCUST_KEY, "D", "전체");
      this.userInfo.ASP_CUST_KEY = event;
    },

    changeBtnDate(strKey){
      if(strKey != 'ALL'){
        this.gridSearch.DAY_FROM = this.mstrDatePattern[strKey];
        this.gridSearch.DAY_TO = this.mstrDatePattern['TO_DAY'];
      }else{
        this.gridSearch.DAY_FROM = "";
        this.gridSearch.DAY_TO = "";
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    startDate(e){
      this.gridSearch.DAY_FROM = e;
    },
    endDate(e){
      this.gridSearch.DAY_TO = e;
    },
    showDialog(type) {
      if(this.gridSearch.ASP_NEWCUST_KEY == '' || this.gridSearch.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;

      } else {
        this[`dialog${type}`] = true;
      }
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    setUser(){
      this.gridSearch.USER_ID = this.userInfo.USER_ID;
      this.gridSearch.USER_NM = this.userInfo.USER_NM;
      this.gridSearch.USER_NICK = this.userInfo.USER_NICK;
    },
    resetBtn(){
      //사용자 조회 초기화
      this.userInfo.USER_ID = '';
      this.userInfo.USER_NM = '';
      this.userInfo.USER_NICK = '';
      this.gridSearch.USER_ID = '';
      this.gridSearch.USER_NM = '';
      this.gridSearch.USER_NICK = '';
    },
    newRtn(state){

      this.registParams.CNTNT_ID = "";
      this.registParams.CNTNT_KEY = "";
      this.registParams.SAVE_STAT_CD = "";
      this.registParams.DIV_ID_1LEVEL = "";
      this.registParams.DIV_ID_2LEVEL = "";
      this.registParams.DIV_ID_3LEVEL = "";
      this.registParams.STATE = "";

      if(state == "NEW"){
        this.registParams.CNTNT_ID = "regist";
        this.registParams.DIV_ID_1LEVEL = this.gridSearch.DIV_ID_1LEVEL;
        this.registParams.DIV_ID_2LEVEL = this.gridSearch.DIV_ID_2LEVEL;
        this.registParams.DIV_ID_3LEVEL = this.gridSearch.DIV_ID_3LEVEL;
      }else if(state == "MODIFY"){
        this.registParams.CNTNT_ID = this.gridSelectedData.CNTNT_ID;
        this.registParams.CNTNT_KEY = this.gridSelectedData.CNTNT_KEY;
        this.registParams.SAVE_STAT_CD = this.gridSelectedData.SAVE_STAT_CD;
      }

      this.registParams.STATE = state;

      if(typeof(this.registParams.CNTNT_ID) === "undefined"){
        this.common_alert("선택된 지식이 없습니다.", "error");
        return;
      }
      this.showDialog('M510201P02');
    },
    async changeDivId(level){
      if(level == '1'){
        if(this.gridSearch.DIV_ID_1LEVEL != ""){
          let DIV_ID_2LEVEL = await this.mixin_getDivCodeData(this.gridSearch.DIV_ID_1LEVEL);
          this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_2LEVEL, this.gridSearch.DIV_ID_1LEVEL,"전체");
          this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get("","","전체");
        }

        this.gridSearch.DIV_ID_2LEVEL = "";
        this.gridSearch.DIV_ID_3LEVEL = "";
      }else if(level == '2'){
        if(this.gridSearch.DIV_ID_2LEVEL != ""){
          let DIV_ID_3LEVEL = await this.mixin_getDivCodeData(this.gridSearch.DIV_ID_2LEVEL);
          this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_3LEVEL, this.gridSearch.DIV_ID_2LEVEL,"전체");
        }

        this.gridSearch.DIV_ID_3LEVEL = "";
      }
    },
    callBack(dialog_id){
      this.hideDialog(dialog_id);
      this.search();
    },
    isActiveRow(item){
      return item.CNTNT_NO == this.gridSelectedData.CNTNT_NO? 'active':'';
    },
    setGridSelect(item){
      this.gridSelectedData = item;
    },
    showDetail(){
      if(this.gridSelectedData.CNTNT_NO != null && this.gridSelectedData.CNTNT_NO != ""){
        this.showDialog('M510201P03');
      }else{
        this.common_alert("선택된 컨텐츠가 없습니다.", "error");
      }
    },

    async processRtn(proc_case){
      if(this.gridChecked.length > 0){
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/km/content/manage/state/modify";
        requestData.headers["SERVICE"] = "km.content.manage.state";
        requestData.headers["METHOD"] = "modify";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        let dataList = [];
        let save_stat_cd = "";
        let idx_stat_cd = "";
        if(proc_case == "APPROVAL"){
          save_stat_cd = "02"
          idx_stat_cd = "01";
        }else if(proc_case == "DEL_CANCEL"){
          save_stat_cd = "02"
          idx_stat_cd = "01";
        }else if(proc_case == "INDEX_DEL"){
          idx_stat_cd = "10"
        }else if(proc_case == "INDEX_READY"){
          idx_stat_cd = "01";
        }


        for(let i = 0; i < this.gridChecked.length; i++){
          dataList.push({
            CNTNT_ID : this.gridChecked[i].CNTNT_ID,
            SAVE_STAT_CD : save_stat_cd,
            IDX_STAT_CD : idx_stat_cd,
            DATA_FLAG : "U",
            AMDR_ID : this.$store.getters['userStore/GE_USER_ID'],
            AMDR_DEPT_CD : "x",
            PROC_ID : this.$store.getters['userStore/GE_USER_ID'],
          })
        }
        // sendData 세팅
        requestData.sendData['DATA'] = dataList;
        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.common_alert(response.HEADER.ERROR_MSG, "done");
          this.gridSelectedData = {};
          this.gridChecked = [];
          this.selectRtnKm();

        }
      }else{
        this.common_alert("선택된 컨텐츠가 없습니다.", "error");
      }
    },
    async deleteRtn(){
      if(this.gridChecked.length > 0){
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/km/content/manage/state/modify";
        requestData.headers["SERVICE"] = "km.content.manage.state";
        requestData.headers["METHOD"] = "modify";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        let dataList = [];

        for(let i = 0; i < this.gridChecked.length; i++){
          dataList.push({
            CNTNT_ID : this.gridChecked[i].CNTNT_ID,
            SAVE_STAT_CD : "03",
            IDX_STAT_CD : "10",
            DATA_FLAG : "U",
            AMDR_ID : this.$store.getters['userStore/GE_USER_ID'],
            AMDR_DEPT_CD : "x",
            PROC_ID : this.$store.getters['userStore/GE_USER_ID'],
          })
        }
        // sendData 세팅
        requestData.sendData['DATA'] = dataList;
        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.common_alert(response.HEADER.ERROR_MSG, "done");
          this.gridSelectedData = {};
          this.gridChecked = [];
          this.selectRtnKm();

        }
      }else{
        this.common_alert("선택된 컨텐츠가 없습니다.", "error");
      }
    },
    moveRtn(){
      if(this.gridChecked.length > 0){
        let validate = this.gridChecked.filter(e => {
          return e.IDX_STAT_CD === "02" || e.IDX_STAT_CD === "03" || e.IDX_STAT_CD === "11"
        });

        if(validate.length > 0){
          this.common_alert("이동할 수 없는 데이터가 포함되어 있습니다.", "error");
        }else{
          this.showDialog('M510201P01');
        }
      }else{
        this.common_alert("선택된 컨텐츠가 없습니다.", "error");
      }
    },
    downloadExcel(table_id, file_name) {
      if(this.mixin_isEmpty(this.gridDataText)){
        this.common_alert("조회 후 가능합니다.", "error");
        return;
      }

      //mixin에 셋팅된 method 호출
      this.table_id = table_id;
      this.file_name = file_name;
      this.mixin_common_exportExcel();
    },
    showFileView(file_group_key){
      if(file_group_key != null && file_group_key != ""){

        this.FILE_GROUP_KEY = file_group_key;
        this.showDialog('FileList');
      }

    },
  },
  computed: {
    dateRangeText () {
      var newStartDate = this.gridSearch.DAY_FROM;
      var newEndDate = this.gridSearch.DAY_TO;
      let returnVal = "";
      if(newStartDate !== '' && newEndDate !== ''){
        returnVal = newStartDate + " ~ " + newEndDate
      }
      return returnVal;
    },

    computedDateFormatted(){
        return this.formatDate(this.date)
    },
    computedDateFormatted2(){
        return `${this.date} ${this.timeStep}`
    }
  },
};
</script>

<style>

</style>

